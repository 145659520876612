import React, { ReactElement, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import './MdorView.scss'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

type PdfViewerPropTypes = {
  location: {
    state: {
      pdf?: any
      token?: string
    }
  }
}

const MDORViewComponent = (location: PdfViewerPropTypes): ReactElement => {
  const [file, setFile] = useState(location?.location?.state?.pdf)
  const [token, setToken] = useState(location?.location?.state?.token)
  const [page, setPage] = useState(1)
  const [zoom, setZoom] = useState(1)

  if (!file && typeof window !== 'undefined') {
    window.location.href = '/deed-of-restriction'
  }

  const mdorFile = {
    url: file,
    httpHeaders: { Authorization: `Bearer ${token}` }
  }

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setPage(numPages)
  }

  return (
    <>
      <div className="mdor-viewer">
        <Document file={mdorFile} onLoadSuccess={onDocumentLoadSuccess}>
          {[...Array(page).keys()].map((page) => (
            <Page pageNumber={page + 1} scale={zoom} renderTextLayer={false} />
          ))}
          <button type="button" className="mdor-viewer__zoom-btn mdor-viewer__zoom-btn--in" onClick={(): void => setZoom(zoom + 0.25)}>
            &#x2b;
          </button>
          <button
            type="button"
            className="mdor-viewer__zoom-btn mdor-viewer__zoom-btn--out"
            onClick={(): void => setZoom(zoom > 1 ? zoom - 0.25 : 1)}
            disabled={zoom === 1}
          >
            &#x2013;
          </button>
        </Document>
      </div>
    </>
  )
}

export default MDORViewComponent
